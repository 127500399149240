<template>
  <div class="news-article" v-if="newsItem">
    <router-link :to="{ name: 'Home', params: { lang: language } }" class="back-button">
      {{ getBackText }}
    </router-link>
    <h1>{{ getTitle }}</h1>
    <p class="date">{{ getPublishedText }} {{ formatDateTime(newsItem.createdAt) }}</p>
    <p class="source" v-if="newsItem.source">
      {{ getSourceText }} <a :href="newsItem.source" target="_blank" rel="noopener noreferrer">{{ newsItem.source }}</a>
    </p>
    <div class="content" v-html="getFormattedContent"></div>
    <div v-if="newsItem.updatedAt && newsItem.updatedAt !== newsItem.createdAt" class="updated-date">
      {{ getEditedText }} {{ formatDateTime(newsItem.updatedAt) }}
    </div>
    <StructuredData :newsItem="newsItem" />
  </div>
  <div v-else class="loading">{{ getLoadingText }}</div>
</template>

<script>
import StructuredData from './StructuredData.vue';
import axios from 'axios';

const apiBaseUrl = process.env.VUE_APP_API_URL || 'http://localhost:3000/api';

export default {
  name: 'NewsArticle',
  components: {
    StructuredData
  },
  props: ['language'],
  data() {
    return {
      newsItem: null
    }
  },
  computed: {
    getTitle() {
      if (!this.newsItem) return '';
      switch(this.language) {
        case 'en': return this.newsItem.titleEn || this.newsItem.title;
        case 'zh': return this.newsItem.titleZh || this.newsItem.title;
        case 'ko': return this.newsItem.titleKo || this.newsItem.title;
        case 'vi': return this.newsItem.titleVi || this.newsItem.title;
        case 'ar': return this.newsItem.titleAr || this.newsItem.title;
        case 'ru': return this.newsItem.titleRu || this.newsItem.title;
        case 'de': return this.newsItem.titleDe || this.newsItem.title;
        case 'fr': return this.newsItem.titleFr || this.newsItem.title;
        default: return this.newsItem.title;
      }
    },
    getFormattedContent() {
      if (!this.newsItem) return '';
      let content = '';
      switch(this.language) {
        case 'en': content = this.newsItem.contentEn || this.newsItem.content; break;
        case 'zh': content = this.newsItem.contentZh || this.newsItem.content; break;
        case 'ko': content = this.newsItem.contentKo || this.newsItem.content; break;
        case 'vi': content = this.newsItem.contentVi || this.newsItem.content; break;
        case 'ar': content = this.newsItem.contentAr || this.newsItem.content; break;
        case 'ru': content = this.newsItem.contentRu || this.newsItem.content; break;
        case 'de': content = this.newsItem.contentDe || this.newsItem.content; break;
        case 'fr': content = this.newsItem.contentFr || this.newsItem.content; break;
        default: content = this.newsItem.content;
      }
      return content ? content.replace(/\n/g, '<br>') : '';
    },
    getPublishedText() {
      return this.language === 'en' ? 'Published:' : 
             this.language === 'zh' ? '发布时间：' : 
             this.language === 'ko' ? '게시일:' : 
             this.language === 'vi' ? 'Đã đăng:' :
             this.language === 'ar' ? 'نشرت:' :
             this.language === 'de' ? 'Veröffentlicht:' :
             this.language === 'fr' ? 'Publié:' :
             'Опубликовано:';
    },
    getSourceText() {
      return this.language === 'en' ? 'Source:' : 
             this.language === 'zh' ? '来源：' : 
             this.language === 'ko' ? '출처:' : 
             this.language === 'vi' ? 'Nguồn:' :
             this.language === 'ar' ? 'المصدر:' :
             this.language === 'de' ? 'Quelle:' :
             this.language === 'fr' ? 'Source:' :
             'Источник:';
    },
    getEditedText() {
      return this.language === 'en' ? 'Edited:' : 
             this.language === 'zh' ? '编辑时间：' : 
             this.language === 'ko' ? '편집됨:' : 
             this.language === 'vi' ? 'Đã chỉnh sửa:' :
             this.language === 'ar' ? 'تم التحرير:' :
             this.language === 'de' ? 'Bearbeitet:' :
             this.language === 'fr' ? 'Modifié:' :
             'Отредактировано:';
    },
    getLoadingText() {
      return this.language === 'en' ? 'Loading...' : 
             this.language === 'zh' ? '加载中...' : 
             this.language === 'ko' ? '로딩 중...' : 
             this.language === 'vi' ? 'Đang tải...' :
             this.language === 'ar' ? 'جار التحميل...' :
             this.language === 'de' ? 'Lade...' :
             this.language === 'fr' ? 'Chargement...' :
             'Загрузка...';
    },
    getBackText() {
      switch(this.language) {
        case 'en': return 'Back to News';
        case 'zh': return '返回新闻';
        case 'ko': return '뉴스로 돌아가기';
        case 'vi': return 'Quay lại Tin tức';
        case 'ar': return 'العودة إلى الأخبار';
        case 'de': return 'Zurück zu den Nachrichten';
        case 'fr': return 'Retour aux Actualités';
        default: return 'Назад к новостям';
      }
    }
  },
  methods: {
    formatDateTime(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      return new Intl.DateTimeFormat(this.getLocale(), options).format(date);
    },
    getLocale() {
      switch(this.language) {
        case 'en': return 'en-US';
        case 'zh': return 'zh-CN';
        case 'ko': return 'ko-KR';
        case 'vi': return 'vi-VN';
        case 'ar': return 'ar-SA';
        case 'de': return 'de-DE';
        case 'fr': return 'fr-FR';
        default: return 'ru-RU';
      }
    },
    async fetchNewsItem() {
      try {
        const response = await axios.get(`${apiBaseUrl}/news/${this.$route.params.id}`);
        this.newsItem = response.data;
        // Ensure all language contents are set, even if they're not present in the response
        const languages = ['En', 'Zh', 'Ko', 'Vi', 'Ar', 'Ru', 'De', 'Fr'];
        languages.forEach(lang => {
          if (!this.newsItem[`content${lang}`]) {
            this.newsItem[`content${lang}`] = this.newsItem.content;
          }
          if (!this.newsItem[`title${lang}`]) {
            this.newsItem[`title${lang}`] = this.newsItem.title;
          }
        });
      } catch (error) {
        console.error('Error fetching news item:', error);
        if (error.response && error.response.status === 404) {
          this.newsItem = null;
        } else {
          console.error('Unexpected error:', error);
        }
      }
    }
  },
  created() {
    this.fetchNewsItem();
  },
  watch: {
    '$route'(to, from) {
      if (to.params.id !== from.params.id) {
        this.fetchNewsItem();
      }
    },
    language() {
      this.fetchNewsItem();
    }
  }
}
</script>

<style scoped>
.news-article {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.dark-theme .news-article {
  background-color: #2c2c2c;
  color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(255,255,255,0.1);
}

h1 {
  color: #333333;
  margin-bottom: 10px;
}

.dark-theme h1 {
  color: #ffffff;
}

.date, .source, .updated-date {
  font-style: italic;
  color: #666666;
  margin-bottom: 10px;
}

.dark-theme .date, .dark-theme .source, .dark-theme .updated-date {
  color: #aaaaaa;
}

.content {
  line-height: 1.6;
  margin-top: 20px;
}

.loading {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

a {
  color: #3498db;
  text-decoration: none;
}

.dark-theme a {
  color: #58a6ff;
}

a:hover {
  text-decoration: underline;
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}

.dark-theme .back-button {
  background-color: #2c3e50;
}

.dark-theme .back-button:hover {
  background-color: #34495e;
}
</style>